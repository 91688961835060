import { useEffect, useState } from 'react';
import Image from 'react-bootstrap/esm/Image';
import Modal from 'react-bootstrap/esm/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IconDownload from '@/components/icons/icon-download';
import PurgeCacheIcon from '@/components/icons/icon-purge-icon';
import LoadingButton from '@/components/loading-button';
import useTranslate from '@/hooks/useTranslate';
import { downloadLink, purgeCache } from '@/shared/api';
import { UTENTE_ADMIN_INTERNO, MEDIA } from '@/shared/constants';
import { getFilename } from '@/shared/utils';
import { setToaster } from '@/state/core';
import { makeGetListingItem } from '@/state/listing';
import { selectUser } from '@/state/user';
import IconClose from '../icons/icon-close';

interface Props {
    id: string;
}

function Detail({ id }: Props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const currentItem = useSelector(makeGetListingItem(id));
    const user = useSelector(selectUser);

    const { fileName, length, notes, prodStd, prodValue, thumbnail, title } = currentItem || { };

    useEffect(function() {
        if (!id) {
            handleClose();
        }
    }, [id]);

    async function handlePurgeCache() {
        if (!fileName) {
            return;
        }

        await purgeCache(fileName);

        if (thumbnail) {
            await purgeCache(`${getFilename(thumbnail)}.${MEDIA.THUMBNAIL_EXTENSION}`);
        }

        dispatch(setToaster({ message: translate.SUCCESS.PURGE_CACHE, level: 'success' }));
    }

    async function handleDownload() {
        if (!fileName) {
            return;
        }

        setLoading(true);

        const anchor = document.createElement('a');
        anchor.href = await downloadLink(fileName, prodStd, prodValue);
        anchor.download = fileName;
        if (openLinkToBlank(fileName)) {
            anchor.target = '_blank';
        }
        anchor.click();

        setTimeout(() => {
            handleClose();
            setLoading(false);
        }, 1000);
    }

    function handleClose() {
        setLoading(false);
        history.replace({ search: '' });
    }

    function renderEmptyCacheButton() {
        if (user.value?.Id !== UTENTE_ADMIN_INTERNO.id) {
            return;
        }

        return (
            <LoadingButton
                icon={<PurgeCacheIcon />}
                onClick={handlePurgeCache}
                size="sm"
                text={translate.PURGE_CACHE.toUpperCase()}
                className='empty-cache-button custom-font-weight-bold'
            />
        );
    }

    function renderLength() {
        if (!length) {
            return;
        }

        return (
            <div className="border-bottom mt-2 mb-2">
                <strong>{translate.LENGTH.toUpperCase()}: </strong>
                <code>{length}</code>
            </div>
        );
    }

    function renderNotes() {
        if (!notes) {
            return;
        }

        return (
            <div>
                <strong>{translate.NOTES.toUpperCase()}</strong>
                <code>{notes}</code>
            </div>
        );
    }

    function openLinkToBlank(fileName: string): boolean {
        return !fileName.endsWith('.' + MEDIA.VIDEO_EXTENSION) && !fileName.endsWith('.' + MEDIA.MAGAZINE_EXTENSION);
    }

    // NB: the title MUST follow this naming convention: MatchTitle - MatchInfo
    function renderTitle() {
        if (title == undefined) {
            return;
        }

        const splittedTitle = title.split(' - ');
        const matchName: string = splittedTitle[0];
        const matchInfo: string = splittedTitle.slice(1).join(' - ');
        return (
            <div className='match-title'>
                <div className='match-name custom-font-weight-bold'>
                    {matchName}
                </div>
                <div className='match-info custom-font-weight-regular'>
                    {matchInfo}
                </div>
            </div>
        );
    }

    if (!id) {
        return null;
    }

    return (
        <Modal className="detail" show={!!id} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title>{renderTitle()}</Modal.Title>
                <div className="close-button p-1 mr-1" onClick={handleClose}><IconClose /></div>
            </Modal.Header>
            <Modal.Body>
                <Image fluid src={thumbnail}/>
                {renderLength()}
                {renderNotes()}
            </Modal.Body>
            <Modal.Footer>
                {renderEmptyCacheButton()}
                <div className="justify-content-end">
                    <LoadingButton
                        loading={loading}
                        onClick={handleDownload}
                        icon={<IconDownload />}
                        text={translate.DOWNLOAD.toUpperCase()}
                        className='download-button custom-font-weight-bold'
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default Detail;
